<template>
  <section class="Reviews">
    <div class="Wrapper">
      <TitleRight :info="titleInfo" />
    </div>
    <Slider :slider-info="slider"/>
  </section>
</template>

<script>
import TitleRight from "./TitleRight";
import Slider from "./Slider";
export default {
  name: "Reviews",
  components: {TitleRight, Slider,},
  data() {
    return {
      titleInfo: {
        title: this.$t('reviews.title'),
        text:this.$t('reviews.text')
      },
      slider: this.$t('reviews.reviews')
    }
  },
}
</script>

<style lang="scss">
.Reviews {
  padding: 15px 0 35px;

  @media (min-width: $screen-l) {
    padding: 50px 0 60px;
  }

}
</style>